<template>
  <v-card elevation="0" max-width="600" rounded="xl" class="mx-auto pa-sm-8 pa-4 pass-reset-send width100">
    <lottie :img="img" :height="120" :width="120" />
    <div class="text-h5 arabic-black text-center my-2 black--text">{{ $t('passReset.title') }}</div>
    <div style="max-width: 420px" class="gray--text text-center mb-16 mx-auto f15">{{ $t('passReset.description') }}</div>
  </v-card>
</template>

<script>
import Lottie from '@/components/lottie.vue';
import img from '@/assets/icon/email.json';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      img: img,
    };
  },
};
</script>
